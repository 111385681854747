<template>
  <div class="logo">
    <img
      src="../assets/logo.svg"
      alt="Logo Claussen Simon Stiftung"
      width="200"
    >
  </div>
</template>

<script>
export default {
  name: 'CLogo'
}
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 3em;

  margin-bottom: 3em;
}
</style>
