<script>
import CLogo from '../components/CLogo.vue'
import CTitle from '../components/CTitle.vue'
import CMessage from '../components/CMessage.vue'

export default {
  name: 'CHomeClosed',

  components: {
    CLogo,
    CTitle,
    CMessage
  },

  computed: {
    today: () => new Date(),
    publishAt () {
      return this.$store.state.publishedInfo.publishAt
    },
    unpublishAt () {
      return this.$store.state.publishedInfo.unpublishAt
    },
    readablePublishAt () {
      return this.$store.state.publishedInfo.readablePublishAt
    },
    readableUnpublishAt () {
      return this.$store.state.publishedInfo.readableUnpublishAt
    },
    isPausedByDate () {
      return this.$store.state.publishedInfo.isPausedByDate
    },
    pause_intro_text () {
      return this.$store.state.textsInfo.pauseText
    }
  }
}
</script>
<template>
  <div>
    <c-logo />
    <c-logo-page />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="pause_intro_text"
      v-html="pause_intro_text"
    />
    <!--eslint-enable-->
    <div v-else>
      <c-title>Hilfsfonds für Projekte mit schutzsuchenden Kulturschaffenden</c-title>

      <p class="mt-8 mb-8">
        Bitte lesen Sie vor dem Ausfüllen des Antragsformulars unbedingt die
        <a
          href="http://www.kulturstiftung-hh.de/artconnects"
          target="_blank"
        >Ausschreibung und Informationen</a>
        und beachten Sie folgende Hinweise: Sie können Ihren Antrag nicht zwischenspeichern. Die Anträge werden in der Form bearbeitet, wie sie eingehen. Wenn Sie im Nachhinein etwas korrigieren oder ergänzen möchten, melden Sie sich bitte beim Team der Hamburgischen Kulturstiftung per
        <a
          href="mailto:info@claussen-simon-stiftung.de"
          target="_blank"
        >E-Mail</a>
        oder telefonisch unter 040 339099). Zur reibungslosen Nutzung des Antragsformulars ist ein aktueller Browser erforderlich:
      </p>

      <ul class="mb-8">
        <li>
          <a
            href="https://www.mozilla.org/firefox/"
            target="_blank"
          >Mozilla Firefox</a>
        </li>
        <li>
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
          >Google Chrome</a>
        </li>
        <li>
          <a
            href="https://www.microsoft.com/edge/"
            target="_blank"
          >Microsoft Edge</a>
        </li>
        <li>
          <a
            href="https://www.opera.com/"
            target="_blank"
          >Opera</a>
        </li>
        <li>
          <a
            href="https://www.apple.com/safari/"
            target="_blank"
          >Apple Safari</a>
        </li>
      </ul>
    </div>
    <br>
    <c-message
      type="error"
      class="errors mb-8"
    >
      <p class="mb-3">
        Das Formular kann aktuell nicht ausgefüllt und abgeschickt werden.
        <template v-if="isPausedByDate ">
          <span> Dies </span>
          <span v-if="publishAt > today">ist </span>
          <span v-else-if="unpublishAt < today">war </span>
          im Zeitraum {{ readablePublishAt }} Uhr bis {{ readableUnpublishAt }} Uhr möglich.
        </template>
      </p>
      <router-link
        :to="{ name: 'request' }"
        class="link"
      >
        <span>Sie können sich das Formular ansehen</span>
      </router-link>
      <span class="mb-3">, jedoch nicht absenden.</span>
    </c-message>
  </div>
</template>

<style lang="scss" scoped>
@use "../css/variables.scss";

.link {
  color: variables.$color-red-100;
}
</style>
