<script>
import CButton from '../components/CButton.vue'
import CTitle from '../components/CTitle.vue'
import CLogo from '../components/CLogo.vue'
import CMessage from '../components/CMessage.vue'

export default {
  name: 'CHomeOpen',

  components: {
    CButton,
    CTitle,
    CLogo,
    CMessage
  },

  computed: {
    today: () => new Date(),
    publishAt () {
      return this.$store.state.publishedInfo.publishAt
    },
    unpublishAt () {
      return this.$store.state.publishedInfo.unpublishAt
    },
    readablePublishAt () {
      return this.$store.state.publishedInfo.readablePublishAt
    },
    readableUnpublishAt () {
      return this.$store.state.publishedInfo.readableUnpublishAt
    },
    isPausedByDate () {
      return this.$store.state.publishedInfo.isPausedByDate
    },
    live_intro_text () {
      return this.$store.state.textsInfo.introText
    }
  }
}
</script>

<template>
  <div>
    <c-logo />
    <c-logo-page />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="live_intro_text"
      v-html="live_intro_text"
    />
    <!--eslint-enable-->
    <div v-else>
      <c-title>Hilfsfonds für Projekte mit schutzsuchenden Kulturschaffenden</c-title>

      <p class="mt-8 mb-8">
        Bitte lesen Sie vor dem Ausfüllen des Antragsformulars unbedingt die
        <a
          href="http://www.kulturstiftung-hh.de/artconnects"
          target="_blank"
        >Ausschreibung und Informationen</a>
        und beachten Sie folgende Hinweise: Sie können Ihren Antrag nicht zwischenspeichern. Die Anträge werden in der Form bearbeitet, wie sie eingehen. Wenn Sie im Nachhinein etwas korrigieren oder ergänzen möchten, melden Sie sich bitte beim Team der Hamburgischen Kulturstiftung per
        <a
          href="mailto:info@claussen-simon-stiftung.de"
          target="_blank"
        >E-Mail</a>
        oder telefonisch unter 040 339099). Zur reibungslosen Nutzung des Antragsformulars ist ein aktueller Browser erforderlich:
      </p>

      <ul class="mb-8">
        <li>
          <a
            href="https://www.mozilla.org/firefox/"
            target="_blank"
          >Mozilla Firefox</a>
        </li>
        <li>
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
          >Google Chrome</a>
        </li>
        <li>
          <a
            href="https://www.microsoft.com/edge/"
            target="_blank"
          >Microsoft Edge</a>
        </li>
        <li>
          <a
            href="https://www.opera.com/"
            target="_blank"
          >Opera</a>
        </li>
        <li>
          <a
            href="https://www.apple.com/safari/"
            target="_blank"
          >Apple Safari</a>
        </li>
      </ul>
    </div>
    <br>
    <c-message
      v-if="publishAt && unpublishAt"
      type="info"
      class="mb-8"
    >
      <p class="mb-3">
        Das Formular kann noch bis {{ readableUnpublishAt }} Uhr ausgefüllt und abgeschickt werden. Danach ist eine Antragstellung erst zur nächsten Antragsfrist wieder möglich.
      </p>
    </c-message>
    <c-button
      tag="router-link"
      :to="{ name: 'request' }"
    >
      Antragsformular
    </c-button>
  </div>
</template>
